// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-alterar-senha-js": () => import("./../../../src/pages/alterar-senha.js" /* webpackChunkName: "component---src-pages-alterar-senha-js" */),
  "component---src-pages-artigos-js": () => import("./../../../src/pages/artigos.js" /* webpackChunkName: "component---src-pages-artigos-js" */),
  "component---src-pages-artigos-strapi-article-slug-js": () => import("./../../../src/pages/artigos/{StrapiArticle.slug}.js" /* webpackChunkName: "component---src-pages-artigos-strapi-article-slug-js" */),
  "component---src-pages-cadastre-um-processo-js": () => import("./../../../src/pages/cadastre-um-processo.js" /* webpackChunkName: "component---src-pages-cadastre-um-processo-js" */),
  "component---src-pages-contato-js": () => import("./../../../src/pages/contato.js" /* webpackChunkName: "component---src-pages-contato-js" */),
  "component---src-pages-engajamento-social-js": () => import("./../../../src/pages/engajamento-social.js" /* webpackChunkName: "component---src-pages-engajamento-social-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-modelo-de-negocio-js": () => import("./../../../src/pages/modelo-de-negocio.js" /* webpackChunkName: "component---src-pages-modelo-de-negocio-js" */),
  "component---src-pages-politica-de-privacidade-js": () => import("./../../../src/pages/politica-de-privacidade.js" /* webpackChunkName: "component---src-pages-politica-de-privacidade-js" */),
  "component---src-pages-sobre-js": () => import("./../../../src/pages/sobre.js" /* webpackChunkName: "component---src-pages-sobre-js" */),
  "component---src-pages-trabalhe-conosco-js": () => import("./../../../src/pages/trabalhe-conosco.js" /* webpackChunkName: "component---src-pages-trabalhe-conosco-js" */),
  "component---src-pages-unidades-js": () => import("./../../../src/pages/unidades.js" /* webpackChunkName: "component---src-pages-unidades-js" */)
}

